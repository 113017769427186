// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

const dmsatTheme = {
  dark: false,
  options: {
    customProperties: true,
  },
  colors: {
    primary: "#021342",
    secondary: "#ea002a",
    golder: "#ffffff",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "dmsatTheme",
    themes: {
      dmsatTheme,
    },
  },
});
