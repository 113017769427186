<template>
  <v-navigation-drawer
    v-model="showDialog"
    location="left"
    class="sidebar"
    :width="sidebarWidth"
    v-if="
      sidebarWidth == 400 ||
      sidebarWidth == 250 ||
      sidebarWidth == screenWidth / 2
    "
  >
    <div class="logo">
      <img :src="require('../../assets/img/logo-white.svg')" alt="" srcset="" />
    </div>
    <div
      class="d-flex justify-center align-center sidebar-content"
      v-if="!isQuestion && !isCompleted"
    >
      <div>
        <h1 class="sidebar-title">Καλως ορίσες στο DMSAT</h1>
        <p class="sidebar-subtitle">
          Τον προσωπικό σου αξιολογητή ψηφιακής ωριμότητας
        </p>
      </div>
    </div>
    <div
      v-if="isCompleted"
      class="d-flex flex-column justify-center sidebar-content w-500"
    >
      <v-icon size="x-large">mdi-check-circle-outline</v-icon>
      <h1 class="sidebar-title">
        Συγχαρητήρια, ολοκλήρωσες το σύντομο ερωτηματολόγιο.
      </h1>
      <div class="sidebar-button-container d-flex flex-column">
        <v-btn
          color="white"
          variant="outlined"
          class="text-none"
          @click="getPDFReport"
          >Κατέβασε τα αποτέλεσματα</v-btn
        >
        <v-btn
          color="white"
          variant="outlined"
          class="text-none"
          @click="goToDashboard"
          >Προσωπικό πλαίσιο διαχείρησης</v-btn
        >
      </div>
    </div>
  </v-navigation-drawer>
  <v-navigation-drawer
    v-model="showDialog"
    :rail="rail"
    location="left"
    class="sidebar"
    :expand-on-hover="false"
    v-else
  >
    <v-list-item
      :prepend-avatar="require('../../assets/img/logo-red.png')"
      nav
      :ripple="false"
      :rounded="false"
    >
      <template v-slot:default>
        <img
          :src="require('../../assets/img/logo-white-lt.png')"
          class="mt-2"
        />
      </template>
    </v-list-item>
    <v-list density="compact" nav class="sidebar-list mt-16" v-if="!isAdmin">
      <v-list-item
        :prepend-avatar="require('../../assets/img/icons/report.svg')"
        title="Ερωτηματολόγια"
        value="dashboard"
        :rounded="false"
        :active="$route.name === 'dashboard'"
      >
      </v-list-item>
      <v-list-item
        :prepend-avatar="require('../../assets/img/icons/user.svg')"
        title="Προφίλ"
        value="account"
        :rounded="false"
      ></v-list-item>
    </v-list>
    <v-list density="compact" nav class="sidebar-list mt-16" v-else>
      <v-list-item
        :prepend-avatar="require('../../assets/img/icons/statistics.svg')"
        title="Ανάλυση"
        value="reporting"
        to="dashboard"
        :rounded="false"
        :active="$route.name === 'dashboard'"
      >
      </v-list-item>
      <v-list-item
        :prepend-avatar="require('../../assets/img/icons/report.svg')"
        title="Ερωτηματολόγια"
        value="reports"
        to="reports"
        :rounded="false"
        :active="$route.name === 'reports'"
      >
      </v-list-item>
      <v-list-item
        :prepend-avatar="require('../../assets/img/icons/user.svg')"
        title="Προφίλ"
        value="account"
        :rounded="false"
      ></v-list-item>
    </v-list>
    <v-list class="logout-item">
      <v-list-item
        prepend-icon="mdi-logout"
        title="Αποσύνδεση"
        @click="logout"
        :rounded="false"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SideBar',
  data() {
    return {
      rail: null,
      showDialog: true,
    };
  },
  props: {
    isQuestion: {
      type: Boolean,
      default: false,
    },
    isCompleted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToDashboard() {
      this.$router.push({ path: '/dashboard' });
    },
    logout() {
      this.$store.dispatch('logout');
    },
    async getPDFReport() {
      if (this.$store.getters.user.currentAssessmentId == null) {
        this.$router.push({ path: '/dashboard' });
        return;
      }

      const assessment = await this.$store.dispatch('getAssessment', {
        id: this.$store.getters.user.currentAssessmentId,
      });

      this.emitter.emit('export-pdf', assessment);
    },
  },
  computed: {
    screenWidth() {
      return window.innerWidth;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    sidebarWidth() {
      if (this.$route.name === 'dashboard' || this.$route.name === 'reports') {
        return 100;
      } else if (this.$route.name === 'questions') {
        return 250;
      } else if (this.$route.name === 'complete') {
        return this.screenWidth / 2;
      } else {
        return 400;
      }
    },
  },
  created() {
    if (this.isMobile) {
      this.showDialog = false;
    }
    this.emitter.on('openSideMenu', () => {
      this.rail = false;
      this.showDialog = true;
    });
    this.emitter.on('toggleSidebar', () => {
      this.rail = !this.rail;
    });
    if (this.$route.name === 'dashboard') {
      this.rail = false;
    }
    this.rail = true;
  },
};
</script>

<style lang="scss">
.sidebar {
  .logout-item {
    position: absolute;
    bottom: 0;
  }
  // padding: 15px 10px;
  border: 0;
  background: rgba(var(--v-theme-primary), 1);
  color: white;
  .logo {
    padding: 20px;
  }
  .sidebar-content.w-500 {
    margin: 0 auto;
    .sidebar-title {
      margin-top: 20px;
    }
  }
  .sidebar-content {
    height: calc(100% - 80px);
    padding: 0 50px;
    .sidebar-title {
      font-size: 45px;
      line-height: 50px;
      margin-bottom: 30px;
    }
    .sidebar-subtitle {
      font-size: 22px;
      font-weight: bold;
      line-height: 26px;
    }
    .sidebar-button-container {
      margin-top: 20px;
      row-gap: 40px;
      .v-btn {
        width: 85%;
        height: 58px;
        font-size: 20px;
        padding: 0 30px;
      }
    }
  }
}
</style>
