import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/start",
    name: "start",
    component: () => import("../views/StartView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/assessment",
    name: "assessment",
    component: () => import("../views/AssessmentView.vue"),
    meta: { authenticated: true },
  },
  {
    path: "/questions",
    name: "questions",
    component: () => import("../views/QuestionView.vue"),
    meta: { authenticated: true },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/DashboardView.vue"),
    meta: { authenticated: true },
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("../views/ReportsView.vue"),
    meta: { authenticated: true },
  },
  {
    path: "/complete",
    name: "complete",
    component: () => import("../views/CompleteView.vue"),
    meta: { authenticated: true },
    // beforeEnter: async (to, from, next) => {
    //   if (store.getters.user.currentAssessmentId == null) {
    //     next({
    //       path: "/dashboard",
    //     });
    //   } else {
    //     next();
    //   }
    // },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  // try auto login
  if (
    !store.getters.isAuthenticated &&
    to.matched.some((record) => record.meta.authenticated)
  ) {
    await store.dispatch("autoLogin", {
      route: to,
    });
  }
});

export default router;
