import axios from '@/plugins/axios';
import { useCookies } from 'vue3-cookies';
import router from '@/router/index';
const { cookies } = useCookies();

export default {
  // eslint-disable-next-line
  loginRedirect: ({ commit, getters }, route) => {
    if (route.fullPath.includes('/login')) {
      if (!getters.isAdmin) {
        if (getters.user.data.numOfAssessments > 0) {
          router.push({ path: '/dashboard' });
        } else {
          router.push({ path: '/assessment' });
        }
      } else {
        router.push({ path: '/dashboard' });
      }
    } else {
      router.push({ path: route.fullPath });
    }
  },
  login: ({ dispatch, commit }, payload) => {
    return axios
      .post('/authenticate', payload)
      .then((res) => {
        const token = res.data.token;

        const d = new Date();

        cookies.set('id_token', token, d.getTime() + 1 * 24 * 60 * 60 * 1000);
        axios.defaults.headers.common.Authorization = 'Bearer ' + token;

        commit('updateToken', token);

        dispatch('getAccount');
      })
      .catch(axios.errorNotify);
  },

  logout: ({ commit }) => {
    cookies.remove('id_token');
    commit('logout');
    window.location = '/login';
  },

  getUserAssessments: ({ commit, getters }) => {
    const params = {
      ...getters.params.filters,
      ...getters.params.pagination,
    };

    params.page = params.page - 1;

    return axios
      .get('/api/assessments/user', { params })
      .then((res) => {
        commit('setPaginationLength', res.data.totalElements);
        commit('setUserAssessments', res.data.content);
      })
      .catch(axios.errorNotify);
  },

  getAdminAssessments: ({ commit, getters }) => {
    const params = {
      ...getters.params.filters,
      ...getters.params.pagination,
    };

    if (getters.params.type != 'ALL' && getters.params.type != undefined) {
      params.type = getters.params.type;
    }

    params.page = params.page - 1;

    return axios
      .get('/api/assessments/admin', { params })
      .then((res) => {
        commit('setPaginationLength', res.data.totalElements);
        commit('setUserAssessments', res.data.content);
      })
      .catch(axios.errorNotify);
  },

  getAdminAssessmentsCount: ({ commit, getters }) => {
    return axios
      .get('/api/assessments/admin/count')
      .then((res) => {
        commit('setAssessmentCount', res.data);
      })
      .catch(axios.errorNotify);
  },

  getAssessment: ({ commit, getters }, payload) => {
    return axios
      .get(`/api/assessment/${payload.id}`)
      .then((res) => {
        return res.data;
      })
      .catch(axios.errorNotify);
  },

  deleteAssessment: ({ commit, getters, dispatch }, payload) => {
    return axios
      .get(`/api/assessment/delete/${payload.id}`)
      .then(async (res) => {
        await dispatch('getUserAssessments');
      })
      .catch(axios.errorNotify);
  },

  getQuiz: ({ commit }, payload) => {
    const params = payload;

    return axios
      .get('/api/quiz', { params })
      .then((res) => {
        return res.data;
      })
      .catch(axios.errorNotify);
  },

  register: ({ dispatch }, payload) => {
    return axios
      .post('/register', payload)
      .then(() => {
        dispatch('login', { email: payload.email, password: payload.password });
      })
      .catch(axios.errorNotify);
  },

  autoLogin: async ({ dispatch, commit }, payload) => {
    const idToken = cookies.get('id_token');

    if (!idToken) {
      return false;
    }
    commit('updateToken', idToken);
    axios.defaults.headers.common.Authorization = 'Bearer ' + idToken;

    await dispatch('getAccount');

    dispatch('loginRedirect', payload.route);
  },

  getAccount: async ({ commit, getters }) => {
    return axios
      .get('/api/account')
      .then((res) => {
        commit('updateUserData', res.data);
        if (res.data.numOfAssessments > 0 || getters.isAdmin) {
          router.push({ path: '/dashboard' });
        } else {
          router.push({ path: '/assessment' });
        }
      })
      .catch(axios.errorNotify);
  },

  submitAnswer: ({ commit }, payload) => {
    return axios.post('/api/quiz/answer', payload);
  },

  renameAssessment: ({ commit }, payload) => {
    return axios.post('/api/assessment/rename', payload);
  },

  startAssessment: async ({ commit, dispatch }, payload) => {
    return axios
      .post('/api/assessment', payload)
      .then(async (res) => {
        await dispatch('setCurrentAssessment', { id: res.data.id });
        router.push({ path: '/questions' });
      })
      .catch(axios.errorNotify);
  },

  setCurrentAssessment: ({ commit }, payload) => {
    return axios
      .post(`/api/user/current-assessment/${payload.id}`)
      .then((res) => {
        commit('updateUserData', res.data);
      })
      .catch(axios.errorNotify);
  },
};
