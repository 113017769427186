import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import store from "./store";
import mitt from "mitt";
import axios from "axios";
import VueAxios from "vue-axios";
import Notifications from "@kyvg/vue3-notification";
import VueCookies from "vue3-cookies";
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
import Vue3Html2pdf from "vue3-html2pdf";
import mixins from "./mixins/mixins";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

loadFonts();

const emitter = mitt();

const app = createApp(App).use(store).use(router).use(vuetify);

app.use(Vue3SimpleHtml2pdf);
app.use(Vue3Html2pdf);

app.use(VueAxios, axios);

app.component("VueDatePicker", VueDatePicker);

app.mixin(mixins);

app.use(VueCookies);

app.use(Notifications);

app.provide("axios", app.config.globalProperties.axios);

app.config.globalProperties.emitter = emitter;

app.config.performance = true;

app.config.globalProperties.$filters = {
  roundPercentage(value) {
    return Math.round(value);
  },
};

app.mount("#app");
