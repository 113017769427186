<template>
  <v-navigation-drawer
    v-model="showDialog"
    location="right"
    temporary
    width="775"
    class="report-drawer"
  >
    <div class="close-btn">
      <v-btn
        color="primary"
        variant="plain"
        icon="mdi-close"
        size="small"
        @click="showDialog = false"
      >
      </v-btn>
    </div>
    <div class="content">
      <h1>{{ user.business.name }}</h1>
      <div class="digital-class">
        Ψηφιακή Κλάση: <span class="rank">ΑΝΑΔΥΟΜΕΝΗ</span>
      </div>
      <ul>
        <li>
          Υπάρχουν αρκετές ψηφιακές πρωτοβουλίες στην επιχείρηση οι οποίες την
          καθιστούν ψηφιακά ανταγωνιστική, ωστόσο εξακολουθεί να απέχει πολύ απο
          τα πολλαπλά κανάλια πωλήσεων, την πελατοκεντρική προσέγγιση και την
          χρήση των επιχειρηματικών δεδομένων.
        </li>
        <li>
          Η στρατηγική του ψηφιακού μετασχηματισμού της επιχείρησης βρίσκεται
          στα πρώτα στάδια υλοποίησης και απαιτεί την δημιουργία ενός δομημένου
          χάρτη πορείας για την μετατροπή της σε μια ψηφιακά ώριμη επιχείρηση.
          Τα σύγχρονα ψηφιακά μέσα και οι νέες τεχνολογίες βρίσκονται σε
          ανταγωνιστικά επίπεδα αξιοποίησης.
        </li>
        <li>
          Οι δυνατότητες του διαδικτύου για την διαφήμιση, τις πωλήσεις και την
          εξυπηρέτηση των πελατών αξιοποιούνται σε μέτριο βαθμό, ενώ η χρήση των
          δεδομένων που λαμβάνονται απο το διαδίκτυο αξιοποιούνται επαρκώς στην
          λήψη στρατηγικών αποφάσεων.
        </li>
      </ul>
      <div class="criteria">
        <div class="title">Πυλώνες</div>
        <div class="scores d-flex flex-column">
          <div
            v-for="score in assessment.scores"
            :key="score.name"
            class="score d-flex align-center"
          >
            <div class="name">{{ score.name }}</div>
            <div class="progress">
              <v-progress-linear
                :model-value="score.score"
                height="30"
                :color="isBestScore(score.score) ? 'primary' : '#94B5E0'"
                bg-color="F7F9FC"
              ></v-progress-linear>
            </div>
            <div class="value d-flex justify-center">
              {{ $filters.roundPercentage(score.score) }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "UserReport",
  data() {
    return {
      showDialog: false,
      assessment: {},
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    isBestScore(score) {
      let scores = [];
      this.assessment.scores.forEach((sc) => {
        scores.push(sc.score);
      });

      let max = Math.max(...scores);

      if (max > score) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.emitter.on("openReport", (data) => {
      this.showDialog = true;
      this.assessment = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.report-drawer {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  color: rgba(var(--v-theme-primary), 1);
  .close-btn {
    position: absolute;
    right: 15px;
    top: 20px;
  }
  .content {
    padding: 30px;
    h1 {
      margin-bottom: 30px;
    }
    .digital-class {
      margin-bottom: 20px;
      .rank {
        font-weight: bold;
      }
    }
    ul {
      margin-bottom: 30px;
    }
    .criteria {
      .title {
        font-weight: bold;
        margin-bottom: 10px;
      }
      .scores {
        row-gap: 10px;
        .score {
          min-height: 50px;
          .name {
            flex: 0 0 24%;
            font-size: 14px;
            padding-right: 5px;
          }
          .progress {
            flex: 0 0 70%;
          }
          .value {
            flex: 0 0 6%;
          }
        }
      }
    }
  }
}
</style>