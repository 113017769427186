module.exports = [
  {
    id: 1,
    link: "/",
    name: "DMSAT",
    reference: "hero-section",
  },
  {
    id: 2,
    link: "/",
    name: "Ψηφιακές κλάσεις",
    reference: "digital-class-section",
  },
  {
    id: 3,
    link: "/",
    name: "Οφέλη",
    reference: "benefits-section",
  },
  {
    id: 4,
    link: "/",
    name: "Διαδικασία",
    reference: "how-to-section",
  },
  {
    id: 5,
    link: "/",
    name: "Κριτήρια Αξιολόγησης",
    reference: "criteria-section",
  },
];
